<template>
  <div>
    <!-- Optional: Display loading spinner while request is in progress -->
    <div v-if="isLoadingSubmit">Processing your request, please wait...</div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import instance from "@/utils/api";

export default {
  name: "Idle-Money-Decline",
  metaInfo: {
    title: "Idle-Money-Decline",
  },
  components: {},
  head() {
    return {
      title: "Idle-Money-Decline",
    };
  },
  data() {
    return {
      isLoadingSubmit: false,
    };
  },
  methods: {
    // ...mapActions({
    //   submitIdleMoney: "idlemoney/submitIdleMoney",
    // }),
    async declineJoinIdleMoney() {
      if (this.isLoadingSubmit) return;

      this.isLoadingSubmit = true;

      const payload = {
        client_id: this.$route.query.client_id,
        is_idle_money: 2,
      };

      try {
        const tmpResponse = await this.submitIdleMoney(payload);
        this.isLoadingSubmit = false;
        if (tmpResponse.status === 200) {
          window.location.href = "/portfolio";
        }
      } catch (error) {
        this.isLoadingSubmit = false;
        console.error("Error processing decline Join IdleMoney:", error);
      }
    },
    // decline-idle-money
    async submitIdleMoney(payload) {
      return await instance.post(
        `${process.env.VUE_APP_ROOT_API}/api/decline-idle-money`,
        payload
      );
    },
  },
  mounted() {
    // Automatically call declineJoinIdleMoney when the component is mounted
    this.declineJoinIdleMoney();
  },
};
</script>
